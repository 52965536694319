exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-applicant-tracking-js": () => import("./../../../src/pages/applicantTracking.js" /* webpackChunkName: "component---src-pages-applicant-tracking-js" */),
  "component---src-pages-attendance-management-js": () => import("./../../../src/pages/attendanceManagement.js" /* webpackChunkName: "component---src-pages-attendance-management-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-onboarding-js": () => import("./../../../src/pages/digitalOnboarding.js" /* webpackChunkName: "component---src-pages-digital-onboarding-js" */),
  "component---src-pages-digital-profiling-js": () => import("./../../../src/pages/digitalProfiling.js" /* webpackChunkName: "component---src-pages-digital-profiling-js" */),
  "component---src-pages-domain-sign-up-js": () => import("./../../../src/pages/domainSignUp.js" /* webpackChunkName: "component---src-pages-domain-sign-up-js" */),
  "component---src-pages-employee-management-js": () => import("./../../../src/pages/employeeManagement.js" /* webpackChunkName: "component---src-pages-employee-management-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faq's.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-file-management-js": () => import("./../../../src/pages/fileManagement.js" /* webpackChunkName: "component---src-pages-file-management-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-job-application-js": () => import("./../../../src/pages/jobApplication.js" /* webpackChunkName: "component---src-pages-job-application-js" */),
  "component---src-pages-meetings-and-conferences-js": () => import("./../../../src/pages/meetingsAndConferences.js" /* webpackChunkName: "component---src-pages-meetings-and-conferences-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/signIn.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-subfeatures-js": () => import("./../../../src/pages/subfeatures.js" /* webpackChunkName: "component---src-pages-subfeatures-js" */),
  "component---src-pages-talent-management-js": () => import("./../../../src/pages/talentManagement.js" /* webpackChunkName: "component---src-pages-talent-management-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/termsOfService.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

